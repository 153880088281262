import { riwLog } from 'src/hooks/riw-logs';
import authAxios from 'src/utils/authAxios';

export const getUserCompany = async userId => {
  let companyResult = null;
  try {
    const axiosRequest = await authAxios();
    companyResult = await axiosRequest.get(
      `${process.env.REACT_APP_BE_APP_URL}/companies/first-company/${userId}`
    );
    return companyResult.data;
  } catch (err) {
    //console.error(`Cannot retrieve company data for current user.`);
    riwLog(
      'error',
      'Companies.ts',
      `User ID: ${userId}`,
      'Cannot retrieve company data for current user',
      err
    );
  }
  return companyResult;
};

export const updateCompanyProfile = async (companyId: string, values: {}) => {
  try {
    const axiosRequest = await authAxios();
    const result = await axiosRequest.post(
      `${process.env.REACT_APP_BE_APP_URL}/companies/update-company-profile/${companyId}`,
      {
        values,
      }
    );
    return result.data;
  } catch (err) {
    throw err.response.data.message
      ? err.response.data.message
      : `Couldn't Update the Company Profile`;
  }
};

// make a call to the backend to delete user and company
export const deleteCompanyAndUsers = async (companyId: string) => {
  try {
    const axiosReq = await authAxios();
    const result = await axiosReq.post(
      `${process.env.REACT_APP_BE_APP_URL}/companies/delete-company-and-users/${companyId}`
    );
    return result.data;
  } catch (err) {
    throw err.response.data.message
      ? err.response.data.message
      : `Couldn't delete your user profile`;
  }
};
