import { submitLoggingData } from 'src/models/Logging';
import { RiwLog } from 'src/types/log';

export const riwLog: RiwLog = async (
  logType,
  context,
  userData,
  message: string,
  jsError
) => {
  // Make sure you can identify logs worth logging in DB
  // if we're in prod, log them
  // if we're in dev, console log them
  // make sure we have enough data as a team to identify and resolve issues
  if (process.env.REACT_APP_FE_ENV === 'production') {
    try {
      submitLoggingData(logType, context, userData, message, jsError);
    } catch (err) {
      console.error("Couldn't send Logs Data to API Server");
    }
  } else {
    submitLoggingData(logType, context, userData, message, jsError);
  }
  return true;
};
