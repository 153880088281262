import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  automationRules: [],
};

const automationRulesSlice = createSlice({
  name: 'automation rules',
  initialState,
  reducers: {
    initializeRules(state, actions) {
      state.automationRules = [...actions.payload];
    },
    submitRule(state, actions) {
      state.automationRules.push(actions.payload);
    },
    deleteRule(state, actions) {
      state.automationRules = state.automationRules.filter(
        r => r.id !== actions.payload
      );
    },
  },
});

export const automationRulesActions = automationRulesSlice.actions;
export const reducer = automationRulesSlice.reducer;
