import axios from 'axios';
import { auth } from 'firebase';

interface metadata {
  userId: string | undefined;
  companyId: string | undefined;
}

const defaultMetadata: metadata = {
  userId: null,
  companyId: null,
};

const authAxios = async (metadata = defaultMetadata) => {
  let firebaseIdToken = null;

  let axiosRequestConfig = {
    baseURL: process.env.REACT_APP_BE_APP_URL,
    headers: {
      Authorization: `Bearer ${firebaseIdToken}`,
    },
  };

  if (metadata) {
    axiosRequestConfig.headers['x-riw-metadata'] = metadata;
    // console.log(axiosRequestConfig)
  }

  try {
    const getFirebaseIdToken = auth().currentUser.getIdToken(true);
    axiosRequestConfig.headers.Authorization = `Bearer ${await getFirebaseIdToken}`;
  } catch (err) {
    console.error('Could not get token from firebase');
    console.error(err);
  }
  return axios.create(axiosRequestConfig);
};

export default authAxios;
