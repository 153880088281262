import authAxios from 'src/utils/authAxios';
import { getUserCompany } from './Companies';
import { getMyUserID } from './User';

export const submitLoggingData = async (
  logType: string,
  context: string,
  userData,
  message: string,
  jsError
) => {
  try {
    const axiosReq = await authAxios();
    const res = await axiosReq.post(`log/submit-log-data`, {
      logType,
      context,
      userData,
      message,
      jsError,
    });

    return res.data;
  } catch (err) {
    throw err.response.data.message
      ? err.response.data.message
      : "Couldn't Log the Data into the Database";
  }
};
