import React, { useContext } from 'react';
import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import UserContext from 'src/contexts/UserContext';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const userContext = useContext(UserContext);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (userContext.signupCompleted === true) {
    return <Redirect to="/app" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
