import React, { useContext } from 'react';
import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import UserContext from 'src/contexts/UserContext';

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const userContext = useContext(UserContext);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (
    userContext.signupCompleted === true &&
    userContext.subscriptionCanceled !== null &&
    (new Date(userContext.subscriptionEndDate?._seconds * 1000) < new Date() ||
      userContext.subscriptionEndDate === undefined) &&
    //this is the day we discontinued the free plan
    userContext.dateCreated > new Date('2024-05-21')
  ) {
    return <Redirect to="/app/pricing" />;
  }

  if (userContext.signupCompleted === false) {
    return <Redirect to="/app/account/form" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
