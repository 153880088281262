import React from 'react';

const FallbackUI2=(err)=>{
    return <React.Fragment>
        <html>
            <head>
                <title>Error occurred!</title>
                
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"/>

                
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

                
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

                
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
            </head>
            <body>
                <div style={{textAlign: 'center', padding: '20px'}}>
                    <h1>Error occurred</h1>
                </div>
                <div style={{textAlign: 'center'}}>
                    <p style={{color: 'red',padding: '10px', fontSize: '18px'}}><b>We were not able to access your data. Please try again later.</b></p>
                <a type="button" className="btn btn-primary" href="/">Try again</a>
                </div>
            </body>
        </html>
    </React.Fragment>;
}
export default FallbackUI2;