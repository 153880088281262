import React from 'react';
import FallbackUI2 from 'src/views/errors/FallbackUI2';

class ErrorBoundary extends React.Component<{}, { hasError: boolean,error: string }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: 'Unidentified error' };
  }
  componentDidCatch(error: any) {
    this.setState({ hasError: true, error: error.toString()});
  }
  render() {
    if (this.state.hasError) {
      return FallbackUI2(this.state.error || 'Unidentified error');
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
