import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';

let logoutTimer;

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  setLogoutTimer: () => any;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  setLogoutTimer: () => null,
  logout: () => Promise.resolve()
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;
  return remainingDuration;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  ////// old token logout code
  // const setLogoutTimer = async () => {
  //   try {
  //     const expirationTime = (await firebase.auth().currentUser.getIdTokenResult()).expirationTime;
  //     const remainingTime = calculateRemainingTime(expirationTime);
  //     remainingTime > 0 ? logoutTimer = setTimeout(logout, remainingTime) : logout();
  //   }
  //   catch (err) {
  //     logout()
  //     console.error('Problem with logout timer. Check expiration time.');
  //   }
  // };

  const setLogoutTimer = async () => {
  try {
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
    let expirationTime = idTokenResult.expirationTime;
    let remainingTime = calculateRemainingTime(expirationTime);

    // Set the buffer time to 5 minutes before expiration
    const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (remainingTime > bufferTime) {
      logoutTimer = setTimeout(() => {
        setLogoutTimer(); // Recursively call to check and refresh token if needed
      }, remainingTime - bufferTime);
    } else {
      // Refresh the token
      const user = firebase.auth().currentUser;
      if (user) {
        await user.getIdToken(true);  // Forces token refresh
        // Reset the logout timer with the new token expiration time
        const newIdTokenResult = await user.getIdTokenResult();
        expirationTime = newIdTokenResult.expirationTime;
        remainingTime = calculateRemainingTime(expirationTime);
        if (remainingTime > bufferTime) {
          logoutTimer = setTimeout(() => {
            setLogoutTimer(); // Recursively call to check and refresh token if needed
          }, remainingTime - bufferTime);
        }
      } else {
        logout();
      }
    }
  } catch (err) {
    logout();
    console.error('Problem with logout timer. Check expiration time.', err);
  }
};

  const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = (): Promise<void> => {
    if (logoutTimer)
      clearTimeout(logoutTimer);
    indexedDB.deleteDatabase("firebaseLocalStorageDb")
    return firebase.auth().signOut().then(() => window.location.replace('/login'))
  };

  // UseEffect for every page reload
  useEffect(() => {
    // setLogoutTimer();
  }, [])

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        setLogoutTimer();
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName || user.email,
              tier: 'Premium'
            }
          }
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
    //eslint-disable-next-line
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        setLogoutTimer,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
